var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"small-menu"},[_c('ul',{staticClass:"user-menu"},[(_vm.hasAvonovaPeople)?[_c('li',{staticClass:"menu-item menu-divider restricted"},[_c('div',{staticClass:"portal-navbar-user-name"},[_c('span',{staticClass:"menu-item-text username"},[_vm._v(" "+_vm._s(_vm.initData.loggedinusername)+" ")]),_c('span',{staticClass:"menu-item-text company"},[_vm._v(" "+_vm._s(_vm.initData.companyname)+" ")])])]),(_vm.hasManagementAccess)?_c('li',{staticClass:"menu-item menu-divider manage-users"},[_c('a',{staticClass:"menu-item-link",attrs:{"href":_vm.toModernUrl('/kontrollpanel/brukeroversikt.xhtml')},on:{"click":function($event){_vm.$emit('menuClicked', {
              title: 'Manage users',
              value: _vm.toModernUrl('/kontrollpanel/brukeroversikt.xhtml')
            })}}},[_vm._m(0),_vm._v(" "+_vm._s(_vm.$t('menu__item__manage_users'))+" ")])]):_vm._e()]:(_vm.isEmployee)?_c('li',{staticClass:"menu-item"},[_c('a',{staticClass:"menu-item-link",attrs:{"id":"portal-navbar-user-name-link","href":_vm.toModernUrl('/ansatte/minside/minSide.xhtml')},on:{"click":function($event){_vm.$emit('menuClicked', {
            title: 'My page menu',
            value: _vm.toModernUrl('/ansatte/minside/minSide.xhtml')
          })}}},[_vm._m(1),_c('span',{staticClass:"menu-item-text"},[_vm._v(" "+_vm._s(_vm.initData.loggedinusername)+" ")])])]):_vm._e(),_c('MenuItemListLanguages',{on:{"click":_vm.changeLang}}),_c('li',{staticClass:"menu-item"},[_c('a',{staticClass:"menu-item-link",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.logout.apply(null, arguments)}}},[_vm._m(2),_c('span',{staticClass:"menu-item-text"},[_vm._v(" "+_vm._s(_vm.$t('menu__item__log_out'))+" ")])])])],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"menu-item-icon"},[_c('span',{staticClass:"fa fa-user"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"menu-item-icon"},[_c('span',{staticClass:"fa fa-user"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"menu-item-icon"},[_c('span',{staticClass:"fa fa-sign-out-alt"})])
}]

export { render, staticRenderFns }