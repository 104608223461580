import { reactive } from 'vue'

import { removeItemAtIndex } from '@/utilities/arrayUtilities.js'
import { isNone } from '@/utilities/conditionalUtilities'
import { toLocalTimezone, toUTCTimezone } from '@/utilities/dateUtilities'

import {
  httpMethods,
  requestHandler,
  requestHandlerWithState
} from '../requestHandler'
import { getNewAccountDTO } from './dto/newAccountDTO'

const state = reactive({
  accounts: [],
  corporateAccounts: [],
  request: {
    loaded: false,
    loading: false,
    saving: false,
    errors: []
  }
})
export const useAdminAccountsService = () => {
  const getAccountsOverview = async () => {
    const url = `/common/api/accounts/overview`
    const response = await requestHandlerWithState(state, httpMethods.GET, url)
    state.accounts = response.data.map(account => {
      return {
        id: account.hms_konto_id,
        name: account.name,
        isTest: account.test_account,
        isCustomer: account.customer_account,
        type: account.customer_account
          ? 'CUSTOMER'
          : account.test_account
          ? 'INTERNAL TEST'
          : 'INTERNAL',
        orgNumber: account.organization_number,
        groupMainAccount: account.corporate_main_account_name,
        hasOnborded: account.onboarding_complete,
        created: account.created,
        disabled: account.disabled,
        subunitno: account.subunitno
      }
    })
  }

  const getCorporateAccounts = async () => {
    const url = `/common/api/accounts/corporate`
    const response = await requestHandlerWithState(state, httpMethods.GET, url)
    state.corporateAccounts = response.data
  }

  const getEditAccountInfo = async id => {
    if (isNone(id)) return { success: true, data: getNewAccountDTO() }
    const url = `/common/api/accounts/editAccount/${id}`
    const result = await requestHandler(httpMethods.GET, url)
    result.data = convertAccountEditDataTimezone(
      result.data,
      toLocalTimezone,
      false
    )
    return result
  }

  const getAllowedProductsInModule = async productCode => {
    const url = `/common/api/products/packages/${productCode}/modules`

    try {
      const response = await requestHandler(httpMethods.GET, url)
      return response.data || []
    } catch (error) {
      console.error('Error fetching modules:', error)
      return []
    }
  }

  const convertAccountEditDataTimezone = (
    data,
    convertFunction,
    disabledDateWithTime = true
  ) => {
    data.disabled_set_date = convertFunction(data.disabled_set_date)
    data.main_user.lastLogin = convertFunction(data.main_user.lastLogin)
    data.account_last_login = convertFunction(data.account_last_login)
    data.account_users = data.account_users.map(user => {
      user.lastLogin = convertFunction(user.lastLogin)
      return user
    })
    data.sub_accounts = data.sub_accounts.map(user => {
      user.disabled = convertFunction(user.disabled)
      return user
    })
    data.disabled_date = convertFunction(
      data.disabled_date,
      disabledDateWithTime
    )
    return data
  }

  const upsertAccountInfo = async accountInfo => {
    const url = `common/api/accounts/createUpdateAccount`
    accountInfo = convertAccountEditDataTimezone(accountInfo, toUTCTimezone)
    const result = await requestHandler(httpMethods.POST, url, accountInfo)
    if (result.success)
      result.data = convertAccountEditDataTimezone(
        result.data,
        toLocalTimezone,
        false
      )
    return result
  }

  const sendWelcomeEmailToMainUser = async (id, email) => {
    const url = 'common/api/accounts/sendWelcomeEmailToMainUserUpdateEmail'
    return await requestHandler(httpMethods.POST, url, {
      user_email: email,
      account_id: id
    })
  }

  const sendLoginInfoToMainUser = async (id, email) => {
    const url = 'common/api/accounts/sendLoginInfoToMainUserUpdateEmail'
    return await requestHandler(httpMethods.POST, url, {
      user_email: email,
      account_id: id
    })
  }

  const sendLoginOrWelcomeEmailToUser = async (id, email) => {
    const url = 'common/api/accounts/overview/sendLoginInfoOrWelcomeEmailToUser'
    return await requestHandler(httpMethods.POST, url, {
      user_email: email,
      user_id: id
    })
  }

  const deleteAccountsByIDs = async ids => {
    const url = 'common/api/accounts/overview/deleteAccounts'
    const response = await requestHandler(httpMethods.POST, url, { ids: ids })

    if (response.success) {
      ids.forEach(id => {
        removeItemAtIndex(state.accounts, id)
      })
    }

    return response
  }

  const changeMainUser = async (accountId, newUserId) => {
    const url = 'common/api/accounts/changeMainUser'
    return await requestHandler(httpMethods.POST, url, {
      hms_konto_id: accountId,
      user_id: newUserId
    })
  }

  return {
    state,
    getAccountsOverview,
    getEditAccountInfo,
    sendWelcomeEmailToMainUser,
    sendLoginInfoToMainUser,
    sendLoginOrWelcomeEmailToUser,
    deleteAccountsByIDs,
    getCorporateAccounts,
    upsertAccountInfo,
    changeMainUser,
    getAllowedProductsInModule
  }
}
