<template>
  <div class="small-menu">
    <ul class="user-menu">
      <template v-if="productPackageName === PRODUCT_PACKAGE.AVONOVA_BASIC">
        <li class="menu-item">
          <a
            class="menu-item-link"
            rel="noopener"
            target="_blank"
            :href="$sanitize($t('menu__item__about_avonova_basic__url'))"
            @click="
              handleMenuClick({
                title: $t('menu__item__about_avonova_basic'),
                value: $t('menu__item__about_avonova_basic__url')
              })
            "
          >
            <img
              :src="
                '/app/img/icons/main-navigation-bar/list' + isWhitebg + '.svg'
              "
              :alt="$t('menu__item__about_avonova_basic')"
            />
            <span class="menu-item-text">{{
              $t('menu__item__about_avonova_basic')
            }}</span></a
          >
        </li>
      </template>
      <template
        v-else-if="productPackageName === PRODUCT_PACKAGE.AVONOVA_PEOPLE"
      >
        <li class="menu-item">
          <a
            class="menu-item-link"
            rel="noopener"
            target="_blank"
            :href="$sanitize($t('menu__item__about_avonova_people__url'))"
            @click="
              handleMenuClick({
                title: $t('menu__item__about_avonova_people'),
                value: $t('menu__item__about_avonova_people__url')
              })
            "
          >
            <img
              :src="
                '/app/img/icons/main-navigation-bar/list' + isWhitebg + '.svg'
              "
              :alt="$t('menu__item__about_avonova_people')"
            />
            <span class="menu-item-text">{{
              $t('menu__item__about_avonova_people')
            }}</span></a
          >
        </li>
      </template>
      <template v-else>
        <li class="menu-item">
          <a
            class="menu-item-link"
            rel="noopener"
            target="_blank"
            :href="$sanitize($t('menu__item__about_avonova_assist__url'))"
            @click="
              handleMenuClick({
                title: $t('menu__item__about_avonova_assist'),
                value: $t('menu__item__about_avonova_assist__url')
              })
            "
          >
            <img
              :src="
                '/app/img/icons/main-navigation-bar/list' + isWhitebg + '.svg'
              "
              :alt="$t('menu__item__about_avonova_assist')"
            />
            <span class="menu-item-text">{{
              $t('menu__item__about_avonova_assist')
            }}</span></a
          >
        </li>
        <li class="menu-item">
          <a
            class="menu-item-link"
            rel="noopener"
            target="_blank"
            :href="$sanitize($t('menu__item__news__url'))"
            @click="
              handleMenuClick({
                title: $t('menu__item__news'),
                value: $t('menu__item__news__url')
              })
            "
          >
            <img
              :src="
                '/app/img/icons/main-navigation-bar/news' + isWhitebg + '.svg'
              "
              :alt="$t('menu__item__news')"
            />
            <span class="menu-item-text">{{ $t('menu__item__news') }}</span></a
          >
        </li>
        <li class="menu-item">
          <a
            class="menu-item-link"
            rel="noopener"
            target="_blank"
            :href="$sanitize($t('menu__item__help_contact__url'))"
            @click="
              handleMenuClick({
                title: $t('menu__item__help_contact'),
                value: $t('menu__item__help_contact__url')
              })
            "
          >
            <img
              :src="
                '/app/img/icons/main-navigation-bar/question' +
                isWhitebg +
                '.svg'
              "
              :alt="$t('menu__item__help_contact')"
            />
            <span class="menu-item-text">{{
              $t('menu__item__help_contact')
            }}</span></a
          >
        </li>

        <li class="menu-item" v-if="!isServicesPage">
          <a
            class="menu-item-link"
            href="/control-panel/avonova-access"
            @click="
              handleMenuClick({
                title: $t('menu__item__company_access'),
                value: '/control-panel/avonova-access'
              })
            "
          >
            <img
              src="/app/img/icons/main-navigation-bar/avonova-access.svg"
              :alt="$t('menu__item__company_access')"
            />
            <span class="menu-item-text">{{
              $t('menu__item__company_access')
            }}</span></a
          >
        </li>
      </template>
      <li class="menu-item">
        <a
          class="menu-item-link"
          :href="$sanitize($t('menu__item__terms_conditions_url'))"
        >
          <img
            src="/app/img/icons/main-navigation-bar/verified.svg"
            :alt="$t('menu__item__terms_conditions')"
            height="18"
          />
          <span class="menu-item-text">{{
            $t('menu__item__terms_conditions')
          }}</span></a
        >
      </li>
    </ul>
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue'

import store from '@/store'

import { PRODUCT_PACKAGE } from '@/constants/productPackages'

export default {
  name: 'HelpMenu',
  components: {},
  props: {
    whiteIcons: { type: Boolean, default: null }
  },
  setup(props, { emit }) {
    const isServicesPage = ref(false)

    const isWhitebg = computed(() => {
      return props.whiteIcons ? '-bg-white' : ''
    })

    const productPackageName = computed(() => {
      return store.getters['authUser/productPackage']?.id
    })

    onMounted(() => {
      if (window.location.pathname === '/services') {
        isServicesPage.value = true
      }
    })

    const handleMenuClick = payload => {
      emit('menuClicked', {
        title: payload.title,
        value: payload.value
      })
    }

    return {
      isServicesPage,
      isWhitebg,
      handleMenuClick,
      productPackageName,
      PRODUCT_PACKAGE
    }
  }
}
</script>

<style lang="scss" scoped>
.small-menu {
  ul {
    padding: 0.5rem;
    margin: 0;
    background: white;
    list-style: none !important;
    li {
      width: 100%;
    }
    li:hover {
      background: #f0f4f2;
    }
    a {
      padding: 6px 12px 6px 15px;
      color: #333;
      text-decoration: none;
      display: flex;
      align-items: center;

      &:focus {
        padding: 6px 12px 6px 15px !important;
        margin: 0 !important;
      }
    }
    img {
      margin-right: 0.429em;
    }
    .menu-item-icon {
      font-size: 1.2em;
      text-align: center;
      display: block;
      width: 30px;
    }
    .menu-item-image-icon {
      width: 30px;
      img {
        height: 20px;
        margin: 0 auto;
        display: block;
      }
    }
  }
}
li.menu-item + li.menu-item {
  margin-top: 8px;
}
</style>
