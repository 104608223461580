export const getNewAccountDTO = () => {
  const newAccountDTO = {
    'hms_konto_id': null,
    'account_name': null,
    'organization_number': null,
    'account_admin_tag': null,
    'country': 'no',
    'disabled_date': null,
    'disabled_set_date': null,
    'terminationType': null,
    'test_account': false,
    'customer_account': false,
    'corporate_account': null,
    'corporate_main_account_name': null,
    'corporate_main_account_id': null,
    'assistCustomer': true,
    'entirebody': false,
    'assistHealth': false,
    'healthInsurance': false,
    'company_name': null,
    'company_email': '',
    'company_address': '',
    'company_zipcode': '',
    'company_area': '',
    'company_phone': '',
    'subunitno': '',
    'company_invoice_person': null,
    'main_user': {
      'user_id': null,
      'mainUser': true,
      'userName': null,
      'firstname': null,
      'lastname': null,
      'name': null,
      'email': null,
      'emp_id': null,
      'userlanguage': null,
      'mfa': null,
      'lastLogin': null
    },
    'login_count': 0,
    'account_last_login': null,
    'number_of_users': 1,
    'show_onboarding': true,
    'account_modules': [
      { 'module_id': 5, 'module_name': 'MANUAL' },
      { 'module_id': 40, 'module_name': 'EMPLOYEESURVEY' },
      { 'module_id': 32, 'module_name': 'DASHBOARD' },
      { 'module_id': 20, 'module_name': 'BASIS' },
      { 'module_id': 21, 'module_name': 'BASIS_PLUS' },
      { 'module_id': 25, 'module_name': 'HR_MODUL' },
      { 'module_id': 26, 'module_name': 'HMS_MODUL' },
      { 'module_id': 1, 'module_name': 'IK_HMS' },
      { 'module_id': 50, 'module_name': 'USER_ADMIN'}
    ],
    'account_users': [],
    'sub_accounts': [],
    'forceMfa': false
  }
  return newAccountDTO
}
