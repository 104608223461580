<template>
  <div
    class="d-flex justify-space-between px-7 py-4"
    id="portal-navigation-bar"
  >
    <div class="d-flex justify-space-between" style="width: 100%">
      <div class="d-flex justify-space-between">
        <AvonovaButton
          id="services-main-menu"
          @click="
            overlay = !overlay
            handleClick({
              value: overlay ? 'Opened' : 'Closed',
              title: 'Side menu mobile view'
            })
          "
          color="green"
          :aria-label="
            overlay
              ? $t('admin_services__close_menu_aria_label')
              : $t('admin_services__open_menu_aria_label')
          "
          class="services-main-menu-button mr-10 d-md-none d-lg-none d-xl-none"
          iconPosition="center"
          height="32"
        >
          <img
            v-if="!overlay"
            src="/app/img/icons/main-navigation-bar/menu.svg"
            alt="close menu"
          />
          <img
            v-if="overlay"
            src="/app/img/icons/main-navigation-bar/e-remove.svg"
            alt="close menu"
          />
        </AvonovaButton>

        <router-link class="d-flex align-center logo zi-15" to="/services">
          <AvonovaTextLogo
            :color="'#00211B'"
            :size="143"
            class="d-none d-sm-none d-md-flex"
          />
          <AvonovaTextLogo
            :color="overlay ? '#FFFFFF' : '#00211B'"
            :size="143"
            class="d-md-none d-lg-none d-xl-none"
          />
        </router-link>

        <div class="overlay-menu">
          <div
            class="services-main-menu-items d-md-none d-lg-none d-xl-none"
            :class="overlay ? 'show-menu' : 'hide-menu'"
          >
            <ul class="user-menu">
              <li class="menu-item" v-if="!hasAvonovaPeople">
                <a
                  class="menu-item-link"
                  :href="$sanitize($t('admin_services__hr_hms__go_to__url'))"
                >
                  <span
                    class="menu-item-text"
                    @click="
                      handleClick({
                        value: $t('admin_services__hr_hms__go_to__url'),
                        title: 'HR and Hse menu link'
                      })
                    "
                    >{{ $t('admin_services__hr_hms__title') }}</span
                  >
                </a>
              </li>
              <li class="menu-item">
                <a
                  class="menu-item-link"
                  target="_blank"
                  :href="$sanitize($t('admin_services__course__go_to__url'))"
                  rel="noopener"
                >
                  <span
                    class="menu-item-text"
                    @click="
                      handleClick({
                        value: $t('admin_services__course__go_to__url'),
                        title: 'Course menu link'
                      })
                    "
                    >{{ $t('admin_services__course__title') }}</span
                  >
                  <span class="arrow-top-right fill-white">
                    <img
                      src="/app/img/icons/main-navigation-bar/arrow-up-white.svg"
                      :alt="$t('admin_services__course__title') + ' icon'"
                    />
                  </span>
                </a>
              </li>
              <li class="menu-item">
                <a
                  class="menu-item-link"
                  target="_blank"
                  :href="
                    $sanitize(
                      $t('admin_services__health_insurance__go_to__url')
                    )
                  "
                  rel="noopener"
                >
                  <span
                    class="menu-item-text"
                    @click="
                      handleClick({
                        value: $t(
                          'admin_services__health_insurance__go_to__url'
                        ),
                        title: 'Health Insurance menu link'
                      })
                    "
                    >{{ $t('admin_services__health_insurance__title') }}</span
                  >
                  <span class="arrow-top-right fill-white">
                    <img
                      src="/app/img/icons/main-navigation-bar/arrow-up-white.svg"
                      :alt="
                        $t('admin_services__health_insurance__title') + ' icon'
                      "
                    />
                  </span>
                </a>
              </li>

              <li class="menu-item expansion-menu">
                <v-expansion-panels class="pa-0">
                  <v-expansion-panel class="expansion-menu-panel" role="menu">
                    <v-expansion-panel-header
                      class="expansion-menu-header"
                      @click="profileOpen = !profileOpen"
                    >
                      {{ $t('menu__item__profile') }}
                      <img
                        :class="profileOpen ? 'rotate-icon' : ''"
                        src="/app/img/icons/main-navigation-bar/chevron-down-white.svg"
                        :alt="$t('menu__item__profile') + ' down icon'"
                        height="23px"
                      />
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="expansion-menu-content">
                      <ProfileMenu
                        :initData="initData"
                        :changeLang="changeLang"
                        :isAdminAccount="isAdminAccount"
                        class="profile-menu"
                        @menuClicked="handleClick"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </li>
              <li class="menu-item expansion-menu">
                <v-expansion-panels class="pa-0 help-menu-expansion">
                  <v-expansion-panel class="expansion-menu-panel" role="menu">
                    <v-expansion-panel-header
                      class="expansion-menu-header"
                      @click="helpMenuOpen = !helpMenuOpen"
                    >
                      {{ $t('menu__item__help') }}
                      <img
                        :class="helpMenuOpen ? 'rotate-icon' : ''"
                        src="/app/img/icons/main-navigation-bar/chevron-down-white.svg"
                        :alt="$t('menu__item__help') + ' down icon'"
                        height="23px"
                      />
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="expansion-menu-content">
                      <HelpMenu
                        class="help-menu"
                        whiteIcons
                        @menuClicked="handleClick"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </li>
            </ul>
          </div>
        </div>

        <div class="align-center d-none d-sm-none d-md-flex">
          <a
            v-if="!hasAvonovaPeople"
            class="navbar-link"
            :href="
              useIframeMonolith
                ? '/index'
                : $sanitize($t('admin_services__hr_hms__go_to__url'))
            "
          >
            <span
              class="menu-item-text"
              @click="
                handleClick({
                  value: $t('admin_services__hr_hms__go_to__url'),
                  title: 'HR and Hse menu link'
                })
              "
              >{{ $t('admin_services__hr_hms__title') }}</span
            >
          </a>
          <a
            v-if="!hideInsuranceAndCourcesLinks"
            class="navbar-link navbar-arrow-link"
            rel="noopener"
            target="_blank"
            :href="$sanitize($t('admin_services__course__go_to__url'))"
          >
            <span
              class="menu-item-text"
              @click="
                handleClick({
                  value: $t('admin_services__course__go_to__url'),
                  title: 'Course menu link'
                })
              "
              >{{ $t('admin_services__course__title') }}</span
            >
            <span class="arrow-top-right">
              <img
                src="/app/img/icons/main-navigation-bar/arrow-up.svg"
                :alt="$t('admin_services__course__title') + ' icon'"
              />
            </span>
          </a>
          <a
            v-if="!hideInsuranceAndCourcesLinks"
            class="navbar-link navbar-arrow-link"
            rel="noopener"
            target="_blank"
            :href="
              $sanitize($t('admin_services__health_insurance__go_to__url'))
            "
          >
            <span
              class="menu-item-text"
              @click="
                handleClick({
                  value: $t('admin_services__health_insurance__go_to__url'),
                  title: 'Health Insurance menu link'
                })
              "
              >{{ $t('admin_services__health_insurance__title') }}</span
            >
            <span class="arrow-top-right">
              <img
                src="/app/img/icons/main-navigation-bar/arrow-up.svg"
                :alt="$t('admin_services__health_insurance__title') + ' icon'"
              />
            </span>
          </a>
        </div>
      </div>
      <div
        class="portal-navigation-menu-right flex-row d-none d-sm-none d-md-flex"
      >
        <div>
          <v-menu
            :min-width="legacySmallOrMobile ? '272' : '242'"
            nudge-bottom="50"
            :left="true"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                depressed
                v-bind="attrs"
                v-on="on"
                :ripple="false"
                class="menu-button"
                id="user-menu-button"
              >
                <svg
                  width="20"
                  height="18"
                  viewBox="0 0 20 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  :aria-label="$t('services__user_menu_alt_label')"
                >
                  <path
                    d="M10.0047 11.2199C12.9647 11.2199 15.3847 8.79994 15.3847 5.81994C15.3847 2.83994 12.9647 0.419941 10.0047 0.419941C7.04469 0.419941 4.62469 2.83994 4.62469 5.81994C4.62469 8.79994 7.04469 11.2199 10.0047 11.2199ZM10.0047 1.99992C12.0847 1.99992 13.7847 3.69992 13.7847 5.79992C13.7847 7.89992 12.0847 9.59992 10.0047 9.59992C7.92469 9.59992 6.22469 7.89992 6.22469 5.79992C6.22469 3.69992 7.92469 1.99992 10.0047 1.99992ZM12.5047 11.7399C12.2447 11.6599 11.9447 11.7199 11.7447 11.8999L10.0047 13.3799L8.26469 11.8999C8.04469 11.7199 7.76469 11.6599 7.50469 11.7399C6.38469 12.0999 0.804688 14.0199 0.804688 16.7999C0.804688 17.2399 1.16469 17.5999 1.60469 17.5999H18.4047C18.8447 17.5999 19.2047 17.2399 19.2047 16.7999C19.2047 14.0199 13.6247 12.0999 12.5047 11.7399ZM2.82469 15.9999C3.70469 15.0599 5.82469 14.0199 7.58469 13.4199L9.48469 15.0399C9.78469 15.2999 10.2247 15.2999 10.5247 15.0399L12.4247 13.4199C14.1847 14.0399 16.3047 15.0799 17.1847 15.9999H2.82469Z"
                    fill="#01332A"
                  />
                </svg>
              </v-btn>
            </template>
            <ProfileMenu
              :initData="initData"
              :changeLang="changeLang"
              :isAdminAccount="isAdminAccount"
              @menuClicked="handleClick"
            />
          </v-menu>
        </div>

        <div class="ml-4">
          <v-menu
            :min-width="legacySmallOrMobile ? '272' : '242'"
            nudge-bottom="50"
            :left="true"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                depressed
                v-bind="attrs"
                v-on="on"
                :ripple="false"
                class="menu-button"
                id="help-menu-button"
              >
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  :aria-label="$t('services__help_menu_alt_label')"
                >
                  <path
                    d="M20.1539 29.3667C20.917 29.3667 21.5357 28.6965 21.5357 27.8697C21.5357 27.043 20.917 26.3727 20.1539 26.3727C19.3907 26.3727 18.772 27.043 18.772 27.8697C18.772 28.6965 19.3907 29.3667 20.1539 29.3667Z"
                    fill="#01332A"
                  />
                  <path
                    d="M16.7 14.0228C17.8643 13.3214 19.1682 12.9361 20.5 12.9C22.9182 12.9 24.3 14.397 24.3 16.6424C24.3 20.3849 20.1546 20.3849 20.1546 24.1273"
                    stroke="#01332A"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="square"
                  />
                  <rect
                    x="1"
                    y="1"
                    width="38"
                    height="38"
                    rx="19"
                    stroke="#01332A"
                    stroke-width="2"
                  />
                </svg>
              </v-btn>
            </template>
            <HelpMenu @menuClicked="handleClick" />
          </v-menu>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue'

import { AvonovaButton } from '@hms-kontoret/avonova-storybook'

import AvonovaTextLogo from './AvonovaTextLogo.vue'
import HelpMenu from './menu-parts/HelpMenu.vue'
import ProfileMenu from './menu-parts/ProfileMenu.vue'

import store from '@/store'
import authUser from '@/store/authUser'

// WORKAROUND FOR FEATURE: USE_IFRAME_MONOLITH
import { useIframeMonolith } from '@/composable/iframe.js'
import { gtmTriggerEvent } from '@/utilities/gtmUtils.js'

import { ACCOUNT_COUNTRY } from '@/constants/i18n'
import { PRODUCT_PACKAGE } from '@/constants/productPackages'

export default {
  name: 'PortalNavigationBar',
  components: { AvonovaTextLogo, AvonovaButton, ProfileMenu, HelpMenu },
  props: {
    initData: { required: true, type: Object },
    changeLang: { required: true },
    isAdminAccount: { required: true, type: Boolean }
  },
  setup(props) {
    const overlay = ref(false)
    const profileOpen = ref(false)
    const helpMenuOpen = ref(false)
    const legacySmallOrMobile = () => {
      return window.matchMedia('(max-width: 768px)').matches
    }

    const handleClick = payload => {
      gtmTriggerEvent(
        'eventTracking',
        'PortalMenu',
        `Clicked on ${payload.title}.`,
        payload.value
      )
    }

    const hideInsuranceAndCourcesLinks = computed(
      () => store.getters['authUser/accountCountry'] === ACCOUNT_COUNTRY.FINLAND
    )

    const hasAvonovaPeople = computed(
      () =>
        store.getters['authUser/productPackageCode'] ===
        PRODUCT_PACKAGE.AVONOVA_PEOPLE
    )

    return {
      legacySmallOrMobile,
      overlay,
      profileOpen,
      helpMenuOpen,
      handleClick,
      useIframeMonolith,
      hideInsuranceAndCourcesLinks,
      hasAvonovaPeople
    }
  }
}
</script>

<style lang="scss" scoped>
#portal-navigation-bar {
  height: 81px;
  width: 100%;
  z-index: 2;
  position: fixed;
  top: 0;
  background-color: white;
}

.logo {
  margin-right: 64px;

  &:focus {
    margin: 0 64px 0 0 !important;
    padding: 0 !important;
  }

  div {
    width: 114px;
    height: 16px;

    svg {
      max-width: 100%;
    }
  }
}

.services-main-menu-items {
  background-color: #00211b;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;

  ul {
    padding: 0.5rem;
    margin: 0;
    margin-top: 80px;
    background: #00211b !important;
    list-style: none !important;

    li {
      width: 100%;
    }

    li:hover:not(.expansion-menu) {
      text-decoration: underline;
    }

    a {
      padding: 6px 12px 6px 20px;
      color: #ffffff !important;
      text-decoration: none;
      display: flex;
      align-items: center;
      font-size: 20px;

      &:focus {
        padding: 6px 12px 6px 20px !important;
        margin: 0 !important;
      }
    }

    img {
      margin-right: 0.429em;
    }
  }
}

.menu-button {
  border: 2px solid #01332a !important;
  border-radius: 100px !important;
  &:hover {
    background-color: #dadada;
  }
  &:focus {
    background-color: #dadada;
    &::before,
    &:hover::after {
      content: ' ';
      position: absolute;
      top: -6px;
      left: -6px;
      right: -6px;
      bottom: -6px;
      border: 1.5px solid #01332a;
      border-radius: 50%;
      opacity: 1;
      background-color: transparent;
    }
  }
}

.hide-menu {
  left: -100%;
  transition: 0.5s;
}

.show-menu {
  left: 0;
  transition: 0.5s;
}

li.menu-item + li.menu-item {
  margin-top: 8px;
}

.navbar-link {
  text-decoration: none !important;
  color: #00211b !important;
  font-weight: 500;
  margin-right: 32px;
  font-size: 20px;
}

.navbar-link:hover {
  text-decoration: underline !important;
}

.navbar-link:focus {
  margin: 0 !important;
  margin-right: 32px !important;
  padding: 0 !important;
}

.arrow-top-right {
  position: relative;
  top: 5px;
  margin-left: 5px;

  img {
    width: 10px;
  }
}

.expansion-menu-panel {
  background-color: #00211b !important;
  color: #ffffff !important;
  border-radius: 0 !important;
}

.expansion-menu-panel .expansion-menu-header {
  padding: 6px 12px 6px 20px !important;
  min-height: unset;
  color: #ffffff !important;
  font-size: 20px !important;
  line-height: 100% !important;

  &:focus {
    outline: 2px solid #005646;
    border-radius: 4px;

    &::before {
      opacity: 0;
    }
  }

  img {
    flex: unset !important;
    transition: 0.3s;
  }

  .rotate-icon {
    transform: rotate(180deg);
    transition: 0.3s;
  }
}

.expansion-menu-content {
  margin-top: 6px;
}

.v-expansion-panel::before {
  content: unset !important;
  box-shadow: unset !important;
}

.zi-15 {
  z-index: 15;
}

@media (min-width: 1024px) {
  .logo div {
    width: 86px;
    height: 12px;
  }

  .navbar-link {
    font-size: 16px;
  }
}

@media (min-width: 1440px) {
  .logo div {
    width: 146px;
    height: 20px;
  }

  .navbar-link {
    font-size: 20px;
  }
}
</style>

<style lang="scss">
#portal-navigation-bar {
  .v-expansion-panel-header__icon {
    display: none !important;
  }

  .services-main-menu-button button {
    width: 56px !important;
    height: 32px !important;
    margin-top: 8px;
    margin-right: 10px;
    z-index: 15;
  }

  .profile-menu ul.user-menu,
  .help-menu ul.user-menu {
    background-color: #00211b !important;

    li {
      a {
        color: #ffffff !important;
      }

      &:hover {
        text-decoration: underline;
        background: unset;
      }
    }
  }
  .menu-item-list-languages {
    background-color: #00211b;
  }
}
</style>
