import { getFileTypeIcon } from '@/composable/common/fileType'
import { DateTime } from '@/composable/luxon/luxon'

import { dateFormat, localizeDate } from './dateUtilities'

export const mapFile = file => {
  const filename = file.name ? file.name : file.original_filename
  return {
    ...file,
    category: file.category,
    contentId: file.contentId,
    id: file.id,
    hms_konto_id: file.hms_konto_id,
    title: file.title ? file.title : filename,
    description: file.description,
    file_sha256_hash: file.file_sha256_hash,
    original_filename: file.original_filename,
    ownerId: file.ownerId,
    ownerType: file.ownerType,
    readaccess: file.readaccess,
    size: file.size,
    created_date: file.lastModified
      ? formatLastModified(file.lastModified) // We are using the milliseconds from dropzones local files
      : localizeDate(file.created_date),
    originalFile: file,
    writeaccess: file.writeAccess ? file.writeAccess : true,
    fileType: getFileTypeIcon({ original_filename: filename })
  }
}

export const escapeUnicode = str => {
  //Copy pasted from drozone's escaping of escapedname
  //The function has been battletested since the dawn of the component which has been
  // used for all uploads and edits from new frontend
  //We might want to consider to moving to a more standardized library for this.
  for (
    var result = '', index = 0, charCode;
    !isNaN((charCode = str.charCodeAt(index++)));

  ) {
    result += '\\u' + ('0000' + charCode.toString(16)).slice(-4)
  }
  return result
}

export const formatLastModified = millis => {
  return dateFormat(DateTime.value.fromMillis(millis))
}

export const blobToBase64 = async blob => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result.split(',')[1])
    reader.onerror = reject
    reader.readAsDataURL(blob)
  })
}

/* 
  This helper should be used when API call 'getFileListForType' already has been used and you need
  a unique list of files to use with print images.
*/
export const generateUniqueFilesListByOwner = fileList =>
  fileList.reduce((acc, current) => {
    const x = acc.find(
      item =>
        item.ownerType === current.ownerType && item.ownerId === current.ownerId
    )
    if (!x) {
      return acc.concat([current])
    } else {
      return acc
    }
  }, [])
