import store from '@/store'

import { orderBy } from '@/utilities/arrayUtilities'
import { addSortIndexIfNotExist } from '@/utilities/arrayUtilities.js'
import { stripHtml } from '@/utilities/stringUtilities.js'

import { INCIDENTS_TYPES } from '@/constants/incidents.js'

const mapCategory = (category, inspectionIdKey, apiInspectionIdKey) => {
  if (category == null) return []

  return category.map(cat => ({
    [inspectionIdKey]: cat[apiInspectionIdKey].toString(),
    id: cat.id.toString(),
    title: cat.title,
    sortIndex: cat.sortIndex ?? null,
    checkpointCount: cat.checkpoints.length,
    checkpoints: mapSafetyInspectionCheckpoints(cat.checkpoints)
  }))
}

const mapCheckpointDeviation = deviation => {
  if (deviation == null) return null

  return {
    id: deviation.id.toString(),
    title: deviation.title,
    date: deviation.date,
    registeredByEmployeeId: deviation?.registerted_by_employee_id?.toString(),
    responsibleEmployeeId: deviation?.responsibleEmployeeId?.toString(),
    description: deviation.description,
    comment: stripHtml(deviation.comment),
    closed: deviation.closed,
    types: deviation?.types?.map(type => ({
      id: type.id.toString(),
      title: type.title,
      type: type.type
    })),
    type: deviation.type
  }
}

const getCheckpointCategoryId = checkpoint => {
  if (checkpoint.safetyInspectionTemplateCategoryId != null)
    return checkpoint.safetyInspectionTemplateCategoryId.toString()
  else if (checkpoint.safetyinspectionCategory_id != null)
    return checkpoint.safetyinspectionCategory_id.toString()
  else return null
}

export const mapSafetyInspectionTemplate = data => {
  return {
    title: data.title,
    id: data.id.toString(),
    systemType: data.systemType,
    description: data.description,
    checkPointCount: data.checkPointCount,
    categories: mapSafetyInspectionTemplateCategory(data.category),
    writeAccess: data.writeaccess ?? false
  }
}

export const mapSafetyInspection = data => {
  if (data == null) return null

  let temp = {
    id: data.id?.toString() ?? null,
    title: data.title,
    description: data.description,
    completionDate: data.created,
    location: data.location,
    departmentId: data.department_id?.toString(),
    createdBy: data.created_by,
    openDeviations: data.open_deviations,
    deviations: data.deviations,
    employeeParticipants: data.employeeParticipants?.map(item =>
      item.toString()
    ),
    contactParticipants: data.contactParticipants?.map(item => item.toString()),
    categories: mapSafetyInspectionCategory(data.category),
    completedCheckpoints: data.completedCheckpoints,
    numCheckpoints: data.numCheckpoints,
    numAssessments: data.numAssessments,
    status: data.status,
    writeAccess: data.writeaccess ?? false
  }

  temp.categories = addSortIndexIfNotExist(temp.categories)

  temp.categories = temp.categories.map(x => {
    return {
      ...x,
      checkpoints: addSortIndexIfNotExist(x.checkpoints)
    }
  })
  return temp
}

export const mapSafetyInspectionTemplateCategory = category => {
  const mappedCat = mapCategory(
    category,
    'safetyInspectionTemplateId',
    'safetyInspectionTemplateId'
  )
  const cleanedCategories = mappedCat.map(category => {
    const { id, title, sortIndex, checkpoints } = category
    return {
      id,
      title,
      sortIndex,
      checkpoints: orderBy(
        checkpoints.map(c => {
          const { id, title, sortIndex } = c
          return { id, title, sortIndex }
        }),
        checkpoint => checkpoint.sortIndex
      )
    }
  })

  return orderBy(cleanedCategories, category => category.sortIndex)
}

export const mapSafetyInspectionCategory = category =>
  mapCategory(category, 'safetyinspectionId', 'safetyinspection_id')

export const mapSafetyInspectionCheckpoint = checkpoint => {
  if (checkpoint == null) return null
  return {
    categoryId: getCheckpointCategoryId(checkpoint),
    safetyinspectionId: checkpoint.safetyinspection_id?.toString(),
    id: checkpoint.id.toString(),
    title: checkpoint.title,
    status: checkpoint.status,
    description: checkpoint.description,
    comment: stripHtml(checkpoint.comment),
    due: checkpoint.due,
    responsible: checkpoint.responsible,
    sortIndex: checkpoint.sortIndex ?? null,
    deviation: mapCheckpointDeviation(checkpoint.deviation)
  }
}

export const mapSafetyInspectionCheckpoints = checkpoints => {
  if (checkpoints == null) return []

  return checkpoints.map(mapSafetyInspectionCheckpoint)
}

export const mapSafetyInspectionTemplateOverview = data => {
  return {
    checkPointCount: data.checkPointCount,
    id: data.id.toString(),
    systemType: data.systemType,
    title: data.title,
    writeAccess: data.writeaccess ?? false
  }
}

export const mapCheckpointsForBackend = checkpoint => {
  if (checkpoint == null) return null
  let deviation = checkpoint?.deviation

  if (deviation != null)
    deviation = {
      comment: stripHtml(deviation.comment),
      description: deviation.description,
      id: parseInt(deviation.id) || null,
      date: deviation.date,
      registerted_by_employee_id:
        parseInt(deviation.registeredByEmployeeId) ||
        parseInt(store.getters['authUser/employeeId']),
      responsibleEmployeeId: parseInt(deviation.responsibleEmployeeId),
      title: deviation.title,
      type: INCIDENTS_TYPES.NON_CONFORMANCE,
      types: [],
      closed: deviation.closed
    }

  return {
    comment: stripHtml(checkpoint.comment),
    description: checkpoint.description,
    deviation: deviation,
    due: checkpoint.due,
    id: parseInt(checkpoint.id) || null,
    responsible: checkpoint.responsible,
    safetyinspectionCategory_id: parseInt(checkpoint.categoryId) || null,
    safetyinspection_id: parseInt(checkpoint.safetyinspectionId) || null,
    sortIndex: checkpoint.sortIndex,
    status: checkpoint.status,
    title: checkpoint.title,
    fileIds: checkpoint.fileIds ?? []
  }
}

export const mapCategoriesAndCheckpointsForBackend = categories => {
  if (categories == null) return null
  return categories.map(category => ({
    id: category.id,
    safetyinspection_id: category.safetyinspectionId,
    title: category.title,
    sortIndex: category.sortIndex,
    checkpoints: category.checkpoints.map(mapCheckpointsForBackend)
  }))
}

export const mapTemplateCategoryForBackend = category => {
  return {
    id: parseInt(category.id) || null,
    safetyinspectionTemplateId:
      parseInt(category.safetyinspectionTemplateId) || null,
    title: category.title,
    sortIndex: category.sortIndex ?? null,
    checkpoints: category.checkpoints.map(mapTemplateCheckpointForBackend)
  }
}

export const mapTemplateCheckpointForBackend = checkpoint => {
  return {
    id: parseInt(checkpoint.id) || null,
    title: checkpoint.title,
    sortIndex: checkpoint.sortIndex ?? null,
    safetyinspectionTemplateId:
      parseInt(checkpoint.safetyinspectionTemplateId) || null,
    safetyinspectionTemplateCategoryId:
      parseInt(checkpoint.safetyinspectionTemplateCategoryId) || null
  }
}

export const mapSafetyInspectionForBackend = inspection => {
  return {
    id: parseInt(inspection.id) || null,
    title: inspection.title,
    description: inspection.description,
    created: inspection.completionDate,
    location: inspection.location,
    department_id: parseInt(inspection.departmentId) || null,
    created_by: inspection.createdBy,
    open_deviations: inspection.openDeviations,
    deviations: inspection.deviations,
    employeeParticipants:
      inspection.employeeParticipants?.map(item => item) ?? [],
    contactParticipants:
      inspection.contactParticipants?.map(item => item) ?? [],
    category: mapCategoriesAndCheckpointsForBackend(inspection.categories),
    completedCheckpoints: inspection.completedCheckpoints,
    numCheckpoints: inspection.numCheckpoints,
    numAssessments: inspection.numAssessments,
    status: inspection.status
  }
}

export const mapSaveSafetyInspectionBody = inspection => {
  return mapSafetyInspectionForBackend(inspection)
}

export const mapSaveSafetyInspectionCategoriesBody = inspection => {
  return mapSafetyInspectionForBackend(inspection)
}

export const mapSavesafetyInspectionCheckpointBody = checkpoint => {
  return mapCheckpointsForBackend(checkpoint)
}

export const mapCreateSafetyInspectionTemplatesBody = inspection => {
  return {
    title: inspection.title,
    category: mapCategoriesAndCheckpointsForBackend(inspection.categories)
  }
}

export const mapUpdateSafetyInspectionTemplatesBody = (id, inspection) => {
  return {
    title: inspection.title,
    category: inspection.categories.map(mapTemplateCategoryForBackend)
  }
}

export const mapSafetyInspectionList = inspectionList =>
  inspectionList.map(mapSafetyInspection)
