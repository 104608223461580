const initDataDefault = {
  'accountLanguage': '', // deprecated
  'userLanguage': '', // deprecated
  'companyname': '',
  'company_phone': '',
  'company_email': '',
  'company_orgnr': '',
  'company_address': '',
  'company_city': '',
  'company_zipcode': '',
  'loggedinusername': '',
  'menuaccess': [],
  'showWelcomeDashboard': false,
  'industry_codes': '',
  'account_id': -1,
  'user_id': -1,
  'employee_id': -1,
  'account_admin_tag': '',
  'test_account': true,
  'number_account_users_allowed': -1,
  'user_type': '',
  'gtm_user_type': '',
  'template_account': false,
  'onboarding_completed_date': '',
  'sub_accounts': [],
  'corporateAccount': false,
  'productPackageCode': null,
}

const state = {
  userLanguage: '', // deprecated
  initData: initDataDefault,
  initDataFetched: false,
  isOverlay: false,
  toggleMainMenu: false,
  productPackageCode: null,
  departments: [],
  premises: [],
  riskCriteria: [],
  riskCriteriaTemplates: [],
  companyInfo: [],
  handbookInfo: [],
  companyLogo: null,
  employees: [],
  inspections: [],
  revisions: [],
  laws: [],
  allLaws: [],
  substances: [],
  workEquipment: [],
  contacts: [],
  boardMembersData: null,
  OhsContacts: [],
  goals: [],
  response: {},
  responseReport: {},
  timeRecorderInSession: null,
  opened: {
    navigationDrawer: false
  },
  notifications: [],
  loaded: {
    app: false,
    departments: false,
    premises: false,
    employees: false,
    inspections: false,
    revisions: false,
    substances: false,
    workEquipment: false,
    contacts: false,
    riskCriteria: false,
    boardMembersData: false,
    bhtMembers: false,
    goals: false
  },
  locales: {
    'nb-NO': 'no',
    'nb-EN': 'en-GB',
    'sv-EN': 'en-GB',
    'sv-SE': 'sv-SE'
  },
  help: {
    activeTab: 1,
    fieldEntryId: ''
  },
  contentfulClient: false,
  contentful: {
    entries: null
  },
  accountType: {
    template: null,
    corporate: null,
    multiUser: null
  },
  maintenanceMethod: ''
}
export default state
