<template>
  <div class="small-menu">
    <ul class="user-menu">
      <!-- Section only visible for main users with people module -->
      <template v-if="hasAvonovaPeople">
        <li class="menu-item menu-divider restricted">
          <div class="portal-navbar-user-name">
            <span class="menu-item-text username">
              {{ initData.loggedinusername }}
            </span>
            <span class="menu-item-text company">
              {{ initData.companyname }}
            </span>
          </div>
        </li>

        <li
          class="menu-item menu-divider manage-users"
          v-if="hasManagementAccess"
        >
          <a
            class="menu-item-link"
            :href="toModernUrl('/kontrollpanel/brukeroversikt.xhtml')"
            @click="
              $emit('menuClicked', {
                title: 'Manage users',
                value: toModernUrl('/kontrollpanel/brukeroversikt.xhtml')
              })
            "
          >
            <span class="menu-item-icon">
              <span class="fa fa-user"></span>
            </span>
            {{ $t('menu__item__manage_users') }}
          </a>
        </li>
      </template>

      <li class="menu-item" v-else-if="isEmployee">
        <a
          id="portal-navbar-user-name-link"
          class="menu-item-link"
          :href="toModernUrl('/ansatte/minside/minSide.xhtml')"
          @click="
            $emit('menuClicked', {
              title: 'My page menu',
              value: toModernUrl('/ansatte/minside/minSide.xhtml')
            })
          "
        >
          <span class="menu-item-icon">
            <span class="fa fa-user"></span>
          </span>
          <span class="menu-item-text">
            {{ initData.loggedinusername }}
          </span>
        </a>
      </li>

      <MenuItemListLanguages @click="changeLang" />
      <li class="menu-item">
        <a class="menu-item-link" href="#" @click.prevent="logout">
          <span class="menu-item-icon">
            <span class="fa fa-sign-out-alt"></span>
          </span>
          <span class="menu-item-text">
            {{ $t('menu__item__log_out') }}
          </span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { computed } from 'vue'

import MenuItemListLanguages from './MenuItemListLanguages.vue'

import router from '@/router'
import store from '@/store'

// WORKAROUND FOR FEATURE: USE_IFRAME_MONOLITH
import { toModernUrl as _toModernUrl } from '@/composable/iframe.js'
import { isSomething } from '@/utilities/conditionalUtilities'

import { PRODUCT_PACKAGE } from '@/constants/productPackages'

export default {
  name: 'ProfileMenu',
  props: {
    initData: { required: true, type: Object },
    changeLang: { required: true },
    isAdminAccount: { required: true, type: Boolean }
  },
  components: {
    MenuItemListLanguages
  },
  setup(props, { emit }) {
    const legacySmallOrMobile = () => {
      return window.matchMedia('(max-width: 768px)').matches
    }

    const isEmployee = computed(() => isSomething(props.initData.employee_id))

    const hasAvonovaPeople = computed(
      () =>
        store.getters['authUser/productPackageCode'] ===
        PRODUCT_PACKAGE.AVONOVA_PEOPLE
    )

    const hasManagementAccess = computed(() =>
      props.initData.menuaccess?.includes('BRUKERSTYRING')
    )

    const logout = e => {
      emit('menuClicked', {
        title: 'Logout',
        value: null
      })
      router.push({ name: 'Logout' })
    }

    const toModernUrl = url => _toModernUrl(url)

    return {
      legacySmallOrMobile,
      logout,
      isSomething,
      toModernUrl,
      hasAvonovaPeople,
      isEmployee,
      hasManagementAccess
    }
  }
}
</script>

<style lang="scss" scoped>
.small-menu {
  > ul {
    padding: 0.5rem;
    margin: 0;
    background: white;
    list-style: none !important;
    li {
      width: 100%;
    }
    a {
      padding: 6px 12px 6px 15px;
      color: #333;
      text-decoration: none;
      display: flex;
      align-items: center;

      &:focus {
        padding: 6px 12px 6px 15px !important;
        margin: 0 !important;
      }

      &:hover {
        background: #f0f4f2;
      }
    }
    img {
      margin-right: 0.429em;
    }

    .menu-item-icon {
      font-size: 1.2em;
      text-align: center;
      display: block;
      width: 30px;
      margin-right: 0.25rem;
    }

    .menu-divider {
      border-bottom: 1px solid #e4e7e5;
      padding: 0px 0px 8px 0px;
      margin-bottom: 10px;

      @media screen and (max-width: 959px) {
        border-bottom: transparent;
        padding: 0px;
      }
    }
  }
}
li.menu-item + li.menu-item {
  margin-top: 8px;
}

.menu-item {
  &.restricted:hover {
    pointer-events: none;
    background: transparent;
  }

  .portal-navbar-user-name {
    line-height: 100%;
    padding: 6px 12px 6px 15px;
    color: #333;
    text-decoration: none;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    .menu-item-text {
      padding: 0 12px 0px 5px;

      &.username {
        font-size: 18px;
        font-weight: 500;
        line-height: 130%;
      }

      &.company {
        font-weight: 400;
        line-height: 150%;
      }

      @media screen and (max-width: 959px) {
        color: white;
      }
    }
  }
}
</style>
